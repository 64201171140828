import logo from './logo.svg';
import './App.css';
import NotesPage from "./components/notesPage";
import Header from "./components/header/Header";

function App() {
  return (
    <div >
      <Header />
      <NotesPage />
    </div>
  );
}

export default App;
