import "./header.css";
function Header() {
    return (
        <div className="header">
            <div className="container">
                <h2 className="headerTitle">Account Deletion Policy</h2>
            </div>
        </div>
    )
}

export default Header;